@import "~@rossum/rossum-ui/sass/variables";

.WhiteText {
  color: $white; }

.GrayText {
  color: $brown-gray; }

.BoldText {
  font-weight: 700; }

.SubmitButton {
  margin: 40px auto 0;
  min-width: 260px; }
