@import "~@rossum/rossum-ui/sass/variables";

.Tooltip {
  text-align: center;
  ul {
    list-style: none;
    text-align: left;
    padding-left: 0; } }

.LimitedWidth {
  max-width: 330px; }

.Icon {
  color: $brown-gray;
  transition: color 0.15s;
  &:hover {
    color: $white; }
  svg {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 5px; } }
