@import "~@rossum/rossum-ui/sass/variables";

.WithBorder {
  border: 1px solid $color-background-1;
  width: 100%;
  border-radius: 10px;
  > div {
    padding: 40px 0;
    > input + div {
      margin-top: 0; } } }

.Dragging {
  position: relative;
  &:after {
    border-radius: 10px;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px dashed $color-text-muted;
    opacity: 0.8; } }

.SampleBox {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin-bottom: -20px; } }

.SampleInfo {
  display: flex;
  align-items: center;
  svg {
    fill: $color-text-muted;
    margin-right: 12px; } }

.Title {
  font-weight: 700; }

.Description {
  font-size: 13px;
  color: $color-text-muted; }

a.SampleFormat {
  text-decoration: underline;
  font-size: 13px;
  opacity: 0.8;
  transition: 0.25s;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px; }

  &:hover {
    text-decoration: none;
    opacity: 1; } }

.Formats {
  display: flex;
  align-items: center; }

@mixin formElements {
  width: 100%;
  max-width: 300px;
  margin-right: auto;
  margin-bottom: -20px;
  display: flex;
  align-items: center;
  > div:first-child {
    flex: 1 0 auto;
    padding-right: 8px; } }

.Select {
  @include formElements(); }

.Input {
  @include formElements(); }
