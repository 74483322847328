@import "~@rossum/rossum-ui/sass/variables";
@import "~@rossum/rossum-ui/sass/fonts";

$lineHeight: 1.42857143;

*, :after, :before {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: $defaultFont;
  font-size: 14px;
  line-height: $lineHeight; }

input {
  line-height: $lineHeight; }

p {
  margin: 0; }
