@import "~@rossum/rossum-ui/sass/variables";

a.GuideLink {
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    text-decoration: none; } }

.InfoText {
  font-size: 13px;
  margin-bottom: 8px;
  color: $brown-gray; }

.SimpleLabel {
  margin-bottom: 8px; }

.FieldGrid {
  display: flex;
  &:last-child {
    margin-bottom: -20px; }
  > * {
    width: 40%;
    max-width: 300px;
    &:not(:last-child) {
      margin-right: 16px; } } }

.Label {
  margin-bottom: 8px;
  display: block;
  font-size: 13px;
  color: $brown-gray;
  font-weight: bold;
  display: flex;
  align-items: flex-end; }
