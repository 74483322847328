@import "~@rossum/rossum-ui/sass/variables";

.Items {
  width: 100%;
  border-radius: 10px;
  border: 1px solid $charcoal-gray-light;
  overflow: hidden;
  margin-bottom: 20px; }

.Item {
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: $color-background-1; } }

.ItemActive {
  background-color: $color-primary;
  &:hover {
    background-color: $color-primary-dark; } }

.Empty {
  padding: 16px;
  text-align: center; }
