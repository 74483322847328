@import "~@rossum/rossum-ui/sass/variables";

.PromoContainer {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 10px; }

a.Link {
  color: $brown-gray;
  font-size: 12px;
  text-decoration: underline;
  &:hover {
    text-decoration: none; } }
