@import "~@rossum/rossum-ui/sass/variables";

.Container {
  max-width: 850px;
  margin: auto;
  padding: 0 15px 80px;
  a {
    color: $white;
    text-decoration: none; } }

.LogoutLink {
  margin-left: auto;
  white-space: nowrap; }

.PendingScreen {
  text-align: center;
  padding-top: 10%;
  color: $white;
  font-size: 18px;
  font-weight: bold;
  svg {
    transform: scale(1.5);
    margin-bottom: 20px;
    circle {
      stroke-width: 2px; } } }
