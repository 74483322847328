@import "~@rossum/rossum-ui/sass/variables";

.ErrorMessage {
  color: $color-error;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 10px; }

.Align-center {
  text-align: center; }

.FieldWrapper {
  margin-bottom: 15px; }
