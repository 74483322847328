@import "~@rossum/rossum-ui/sass/variables";
@import "../../utils";

$buttonSize: 24px;

$dragHandlerWidth: 30px;
$buttonCellWidth: 40px;

$startIndex: 2;
$numberOfColumns: 4;
$lastIndex: $numberOfColumns + 1;

.GuideList {
  line-height: 1.8;
  margin-bottom: -40px; }

.SelectWrapper {
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: -20px; } }

.Select {
  width: 100%;
  max-width: 300px; }

.ErrorMessage {
  margin-top: 8px;
  &:last-child {
    margin-bottom: -30px; } }

.MatchingTable {
  width: 100%;
  margin-left: -10px;
  font-size: 13px; }

.MatchingTableRow {
  display: flex;
  align-items: stretch;
  width: 100%; }

.MatchingTableBodyCell {
  padding: 4px 0;
  display: flex;
  align-items: flex-start;
  width: 100%; }

.MatchingTableHeadCell {
  color: $brown-gray;
  font-weight: bold;
  padding: 4px 0px; }

.MatchingTableHeadCell,
.MatchingTableBodyCell,
.AddRowCell {
  flex: 1 1;
  &:first-child { // drag handler column
    padding: 0;
    flex: 0 0 $dragHandlerWidth; }
  &:nth-child(2) { // master data column
    flex-basis: 30%; }
  &:nth-child(3) {  // captured field column
    flex-basis: 30%; }
  &:nth-child(4) { // matching technique
    flex-basis: 25%; }
  &:nth-child(5) { // threshold column
    flex-basis: 15%; }
  &:last-child { // remove/add button column
    flex: 0 0 $buttonCellWidth;
    justify-content: flex-end; } }

.AddRowCell {
  padding-top: 20px;
  padding-right: 8px;
  &:nth-child(4) {
    flex: 1 1 40%;
    padding-right: 0; }
  &:last-child {
    padding-right: 0;
    display: flex;
    align-items: center; } }

.AddRowDisabled,
.SelectPending,
.RuleRowDisabled {
  opacity: 0.4;
  pointer-events: none; }

.Button {
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  color: $white;
  border-radius: $buttonSize;
  width: $buttonSize;
  height: $buttonSize;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  svg {
    display: block;
    flex: 1 0 16px; } }

.RemoveButton {
  background: $color-error;
  transform: scale(0);
  &:hover {
    background: $color-error-dark;
    opacity: 0.85; }
  .MatchingTableRow:not(.RuleRowDraggingActive):hover & {
    transform: scale(1); }
  .MatchingFlowTableRow:hover & {
    transform: scale(1); } }

.AddButton {
  background: $color-primary;
  &:hover {
    background: $color-primary-dark;
    opacity: 0.85; } }

.RuleRow {
  &:hover .MatchingTableBodyCell {
    background: $grayDark;
    &:nth-child(#{$startIndex}) {
      border-radius: 8px 0 0 8px; }
    &:nth-child(#{$lastIndex}) {
      border-radius: 0 8px 8px 0; }
    &:first-child, &:last-child {
      background: transparent; } }
  &.RuleRowDraggingActive:hover .MatchingTableBodyCell {
    background: transparent; } }

@mixin dragger {
  width: 2px;
  height: 2px;
  background: $white;
  position: absolute; }

.DragHandler {
  opacity: 0.3;
  position: relative;
  width: $dragHandlerWidth;
  height: $dragHandlerWidth;
  display: flex;
  align-items: center;
  top: 3px;
  &:hover {
    opacity: 1; } }

.DragHandlerInnerLeft, .DragHandlerInnerRight {
  @include dragger;
  &:before, &:after, {
    content: '';
    @include dragger; }
  &:before {
    top: 5px; }
  &:after {
    top: -5px; } }

.DragHandlerInnerLeft {
  left: 50%;
  transform: translateX(-3px); }

.DragHandlerInnerRight {
  left: 50%;
  transform: translateX(2px); }

.TextMuted {
  color: $charcoal-gray-light-3;
  font-style: italic; }

$cellPadding: 12px;

.CellValue {
  padding: 5px $cellPadding; }

.HeaderWithTooltip {
  display: flex;
  align-items: center; }

.ThresholdError {
  color: $color-error;
  position: absolute;
  right: 0;
  white-space: nowrap; }

.ThresholdErrorPlaceholder {
  height: 18px;
  position: relative;
  margin-top: 5px; }

.ThresholdInput {
  padding: 4px $cellPadding; }

.CellInput {
  padding-top: 1px;
  padding-right: $cellPadding; }

.ErrorMasterData {
  height: 36px;
  position: relative;
  > div {
    position: absolute;
    width: 260px; } }



.MatchingFlowTable {
  width: 100%;
  margin-left: -10px;
  font-size: 13px; }

.MatchingFlowTableRow {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-bottom: 10px;

  // Operator rows
  &:nth-child(2n) {
    margin-left: 60px; } }

.MatchingFlowTableCell {
  flex: 1 1;
  &:first-child { // drag handler column
    padding-top: 7px;
    flex: 0 0 $dragHandlerWidth; }
  &:nth-child(2) { // number
    flex: 0 0 20px;
    align-self: center; }

  &:nth-child(3) { // rules
    @include custom-scroll(transparent, var(--color-primary), 2px, 2px);
    overflow: scroll;
    display: flex;
    flex-direction: row;
    align-items: center;
    > * {
      margin-right: 10px; }
    &:hover {
      background-color: $grayDark;
      border-radius: 8px; }
    padding: 10px; }
  &:last-child { // remove button column
    padding-top: 13px;
    padding-left: 10px;
    flex: 0 0 $buttonCellWidth;
    justify-content: flex-end; } }

.AddConditionButton {
  align-self: flex-start;
  margin-top: 20px; }

.MatchingFlowOperator {
  flex: 0 0 75px; }

.MatchingFlowRule {
  flex: 0 0 150px; }
