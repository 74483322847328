@mixin custom-scroll($background: transparent, $thumb: rgba(45, 43, 50, 0.75), $width: 8px, $height: 8px) {
  &::-webkit-scrollbar {
    height: $height;
    width: $width;
    background: $background; }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb;
    -webkit-border-radius: 50px; }

  &::-webkit-scrollbar-corner {
    background-color: $background; } }
