@import "~@rossum/rossum-ui/sass/variables";

.Container {
  max-width: 300px;
  margin: auto;
  color: $white; }

.Header {
  margin-bottom: 15px;
  padding-top: 50px; }

.LoginButton {
  margin: auto; }
