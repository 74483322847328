.RemoveButton {
  margin-left: auto; }

.ModalBody {
  padding: 20px; }

.ModalButtons {
  padding: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  button:first-child {
    margin-right: 12px; } }
