@import "~@rossum/rossum-ui/sass/variables";

.Paragraph {
  margin-bottom: 16px;
  color: $brown-gray;
  &:last-child {
    margin-bottom: -20px; } }

.Headline {
  margin-top: 40px;
  margin-bottom: 8px; }
