@import "~@rossum/rossum-ui/sass/variables";

.Header {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  svg {
    transform: scale(1.1);
    margin-right: 15px;
    circle {
      stroke-width: 3px; } } }

.Headline {
  font-weight: 700;
  font-size: 18px; }
